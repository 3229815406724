body {
  font: 1.5vw "Petrona", serif;
  background-color: black;
}

.description {
  width: 60%;
  margin-top: 3em !important;
  margin: 0 auto;
  font-weight: bold;
  color: white;
}

.megaphone {
  width: 100px;
  height: 100px;
  margin-top: 60px;
}

.action {
  width: auto;
  height: 58px;
  background-color: #FFC52F;
  font-size: 30px;
  margin-top: 63px;
  color: black;
  font-weight: bold;
}

.action:hover, .action:visited, .action:active {
  background-color: #FFC52F;
}

.distribute {
  background-color: #FFE7CC;
  width: 100vw;
  height: 561px;
}

.distribute h1 {
  font-size: 40px;
  font-weight: bold;
  padding-top: 93px;
}

.distribute p {
  padding-top: 0;
}

.spotlight {
  margin-top: 200px;
}

.spotlight img {
  width: 467px;
  height: 267px;
}

.approval img {
  width: 456px;
  height: auto;
}

.approval {
  margin-top: 200px;
}

.boost {
  margin-top: 200px;
}

.boost img {
  width: 448px;
}

.communication {
  margin-top: 200px;
}

.communication img {
  width: 374px;
}

.analytics {
  margin-top: 200px;
}

.analytics img {
  width: 500px;
}

.container {
  text-align: center;
  width: 100% !important;
  max-width: 100%;
}

section {
  height: 100vh;
}

#map {
  height: 100vh;
  width: 100vw;
}

img {
  max-width: 100%;
  max-height: 100%;
}

@media only screen and (min-width: 601px) {
  .container {
    width: 85%;
  }
}
@media only screen and (min-width: 993px) {
  .container {
    width: 70%;
  }
}

@media only screen and (max-width: 768px) {
  .contact {
    font-size: 5.6vw;
  }

  h1 {
    font-size: 10vw;
  }

  .logo {

  }

  .description {
    width: 100%;
  }

  .slogan {
    margin-top: 10vh;
    font-size: 7vw;
  }

  p, span {
    font-size: 6vw;
  }

  .distribute {
    height: auto;
  }

  .distribute p {
    padding-top: 0;
  }

  .footer {
    font-size: 5vw;
    display: inline;
  }
}
