.map {
  height: 100vh;
  width: 100vw;
  float: right;
}

.container {
  width: 100%;
  max-width: none;
}

.sidebar {
  width: 20%;
  height: 100vh;
  float: left;
}

.project p {
  color: black !important;
}

.input {
  color: black !important;
}

.select, .select input {
  color: black !important;
}

.projectTitle {
  color: black !important;
}
