.video {
  width: 100vw;
}

.recording {
  color: red;
}

.error {
  color: red;
}
