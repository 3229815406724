.submit {
  margin-top: 15vh;
}

.video {
  height: 50vh;
  width: 50vw;
}

.record {
  margin-top: 6vh;
}

video {
  height: 50vh;
  width: 50vw;
}

h4 {
  color: white;
}
