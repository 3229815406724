.map {
  height: 30vh;
}

ul {
  list-style: none;
}

.title {
  text-align: center;
}

.subtitle {
  text-align: center;
  font-size: 2rem;
}

.description {
  text-align: center;
}

.video {
  background-color: #FFC52F;
  margin-top: 5vh;
  height: 10vh;
  align-self: center;
  margin-bottom: 5vh;
  text-align: center;
  padding-right: 1vw;
}

.video a {
  color: black;
}

/* Media queries - Responsive timeline on screens less than 600px wide */
@media screen and (max-width: 600px) {

}
