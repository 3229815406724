#toast-container {
  min-width: 10%;
  top: 50%;
  right: 50%;
  transform: translateX(50%) translateY(50%);
}

.blog {
  color: black;
}

.btn, .btn:hover, button {
  background-color: #FFC52F !important;
}

.black input, .black h3, .black h1, .input-field .helper-text, .black label, .black p, .black textarea {
  color: white;
}


.learn {
  color: black !important;
}
