.title {
  font-size: 2rem;
}

.img {
  width: 30%;
}

@media screen and (max-width: 600px) {
  .img {
    width: auto;
    max-height: 40vh;
  }
}
