
.carousel-root {
  height: auto;
  /* margin: 0 auto; */
}

.carousel {
  height: auto;
}

.glider img {
  max-height: 80vh;
}

.srv-validation-message {
  color: red;
}
